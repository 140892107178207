interface RoleConfig {
    canAccess: string[];
    canPerform: string[];
}

interface RolesConfig {
    [key: string]: RoleConfig;
}

export const rolesConfig: RolesConfig = {
    admins: {
        canAccess: ['*'],
        canPerform: ['edit'],
    },
    viewer: {
        canAccess: ['*'],
        canPerform: ['view'],
    },
};
