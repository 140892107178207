import styled, {keyframes} from 'styled-components';
import {interFontStyle} from './Fonts';


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;



export const ErrorContainer = styled.div`
  position: relative;
  text-align: center; /* Center children horizontally */
  max-width: 500px;
  margin: 0 auto;
  opacity: 0;
  animation: ${fadeIn} 2s ease-out forwards; /* Apply the fade-in animation */

  img {
    width: 100%;
  }
  ${interFontStyle}
`;

export const ErrorMessage = styled.div`
  --original-font-size: 60px; /* Define original font size as a CSS variable */

  position: absolute;
  top: 74%;
  left: 52%;
  transform: translate(-50%, -50%);
  color: black;
  width: 100%;
  font-size: var(--original-font-size); /* Use the CSS variable */
  padding: 0.5em;
  max-width: 38%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
