import React, { ComponentType } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { rolesConfig } from './rolesConfig';

const withAuthorization = (WrappedComponent: ComponentType<any>, requiredRoles: string[]) => {
    return (props: any) => {
        const { userGroups } = useAuth();
        const location = useLocation();

        const hasAccess = userGroups.some((group: string) => {
            const roleConfig = rolesConfig[group];
            return roleConfig?.canAccess.includes('*') || roleConfig?.canAccess.includes(location.pathname);
        });

        return hasAccess ? <WrappedComponent {...props} /> : <Navigate to="/no-access" />;
    };
};

export default withAuthorization;
