import {css} from 'styled-components';

export const labelStyle = css`
  label.form-label {
    color: #667085;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
  }
`;

export const inputStyle = css`

  input.form-control:focus {
    box-shadow: 0px 0px 0px 4px rgba(21, 112, 239, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  input.form-control.is-invalid {
    border-color: #FDA29B;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  input.form-control {
    color: #293056;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    border-color: #AFB5D9;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    /* Styling the placeholder */

    ::placeholder {
      color: #AAA; // Replace this with your desired placeholder text color
      opacity: 1; // Optional: Adjust the opacity of the placeholder text
    }

  }


  .form-check-input:checked {
    /* Active state background and border */
    background-color: #4ADE80 !important;
    border-color: #58C17F !important;
  }

  .form-switch .form-check-input {
    width: 45px;
    height: 25px;
  }


`;
