import React, {FC, useRef, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {ErrorContainer, ErrorMessage} from '../styles/ErrorPage';

const adjustFontSizeFor = (element: HTMLElement | null) => {
    if (!element) return;

    // Reset to original font size to measure
    element.style.fontSize = getComputedStyle(element).getPropertyValue('--original-font-size');
    const maxWidth = element.clientWidth;
    let fontSize = parseFloat(getComputedStyle(element).fontSize);
    let contentWidth = element.scrollWidth;

    // Adjust font size if content overflows
    while (contentWidth > maxWidth && fontSize > 10) {
        fontSize -= 1; // Decrease font size
        element.style.fontSize = `${fontSize}px`;
        contentWidth = element.scrollWidth;
    }
};

interface NoMatchProps {
    errorMessage: string;
}

const NoMatch: FC<NoMatchProps> = ({errorMessage}) => {
    const errorMessageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleResize = () => adjustFontSizeFor(errorMessageRef.current);
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container fluid>
            <Row>
                <Col xs={12}>
                    <ErrorContainer>
                        <img src="/images/slothAnimation.gif" alt="Error Animation"/>
                        <ErrorMessage ref={errorMessageRef}>{errorMessage}</ErrorMessage>
                    </ErrorContainer>
                </Col>
            </Row>
        </Container>
    );
};

export default NoMatch;
