import {Helmet} from "react-helmet";
import {css} from 'styled-components';

// Export individual font styles
export const interFontStyle = css`
  font-family: 'Inter', sans-serif;
`;

export const robotoFontStyle = css`
  font-family: 'Roboto', sans-serif;
`;


// Component to inject font links into the document head
export const FontLoader = () => (
    <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"/>
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Roboto:wght@400;500;600&display=swap" rel="stylesheet"/>
    </Helmet>
);
