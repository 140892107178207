import {createSlice} from '@reduxjs/toolkit';

export const userRoleSlice = createSlice({
    name: 'user-role',
    initialState: {
    role: undefined,
    },
    reducers: {
        addRole: (state, action) => {
            if(action.payload){
                state.role = action.payload
            }
        },
    }
});

// this is for dispatch
export const {addRole} = userRoleSlice.actions;

// this is for configureStore
export default userRoleSlice.reducer;
