import React, { useState, FC } from 'react';
import { Navbar, Button, Container, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink, useLocation } from "react-router-dom";
import logo from "../img.png";
import { HeaderProps } from "../types";
import {isEnvironment} from "../utils/utils";
import {DevMode} from "../styles/CommonComponents";

import {
    StyledNavItem,
    StyledNavbarText,
    NavbarContainer,
    NavDropdownContainer,
    SignOutMobile
} from '../styles/NavbarStyles';
import { getInitials } from "../utils/utils";

const Header: FC<HeaderProps> = ({ userName, signOut }) => {
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();

    const handleToggle = () => setExpanded(!expanded);
    const isHomePage = location.pathname === '/';

    return (
        <NavbarContainer variant="light" sticky="top" expand="lg" expanded={expanded}>
            <Container fluid>
                <Navbar.Brand className="logo" as={NavLink} to="/">
                </Navbar.Brand>
                {!isHomePage && (
                    <>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggle} />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="w-100 justify-content-center mx-auto" variant="underline">
                                {['/sites', '/presets', '/publishers'].map((path) => (
                                    <StyledNavItem key={path}>
                                        <NavLink to={path} className={({ isActive }) => isActive ? 'active' : ''}>
                                            {path.substring(1)}
                                        </NavLink>
                                    </StyledNavItem>
                                ))}
                                <SignOutMobile>
                                    <Button className="mt-3 mb-3" variant="danger" onClick={signOut}>Sign out</Button>
                                </SignOutMobile>
                            </Nav>
                        </Navbar.Collapse>
                    </>
                )}
                <NavDropdownContainer>
                    <StyledNavbarText>
                        <span>{getInitials(userName || '')}</span>
                    </StyledNavbarText>
                    <NavDropdown className="d-flex align-items-center" title={userName} id="basic-nav-dropdown">
                        <NavDropdown.Item>
                            <Button variant="text-secondary" onClick={signOut}>Sign out</Button>
                        </NavDropdown.Item>
                    </NavDropdown>
                </NavDropdownContainer>
            </Container>
        </NavbarContainer>
    );
};

export default Header;
