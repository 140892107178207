import {styled, css} from 'styled-components';
import {Nav, Navbar} from 'react-bootstrap';
import {robotoFontStyle} from '../styles/Fonts'
import {device} from '../styles/Breakpoints'


export const NavbarCollapse = css`

  .navbar-collapse {
    @media ${device.md} {
      margin-top: 20px;
    }
  }

`;
export const NavDropdownContainer = styled(Nav)`
  @media ${device.md} {
    display: none;
  }
`;

export const SignOutMobile = styled(Nav.Item)`

  button {
    width: 100%;
  }

  display: none;
  @media ${device.md} {
    display: block;
  }
`;

export const NavbarContainer = styled(Navbar)`
  box-shadow: 0px 1px 2px 0px #1018280D;
  background: #fff;
`;

export const StyledNavbarText = styled(Navbar.Text)`
  ${robotoFontStyle}
  color: #343A40;

  span {
    background: #D8EBFE;
    padding: 10px 12px;
    border-radius: 50px;
    font-size: 12px;
    color: #2E90FA;
    line-height: 14px;
    margin-right: 5px;
  }

  @media ${device.md} {
    display: none;
  }

`;

export const StyledNavItem = styled(Nav.Item)`
  ${robotoFontStyle}
  a.active {
    background: #2F80ED;
    color: #fff;
    border-radius: 6px;
  }

  a {
    padding: 4px 16px;
    display: block;
    color: #667085;
    margin-right: 10px;
    font-size: 18px;
    line-height: 28px;
  }
`;