import styled from 'styled-components';

import {inputStyle, labelStyle} from './FormControl';


export const LoginWrapper = styled.div`

  img {
    width: 100%;
    margin-bottom: 20px;
  }

  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  position: absolute; // Adjusted for centering
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${labelStyle}
  ${inputStyle}
`;

