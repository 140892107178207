export function capitalizeString(str: string) {
    return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
}

export function convertConfigName(name: string | undefined) {
    return name && name.replace(/.config\.js/g, "");
}

export function convertPresetName(name: string) {
    return name && name.replace(/.preset\.js/gi, "");
}

export function convertPublisherName(name: string) {
    return name && name.replace(/.publisher\.js/gi, "");
}

export const systemChangeTypes = ["newModule", "deletedModule", "newVariable", "deleteVariable"];

//Pattern for adding a new SIte, Preset or slot
export const siteNamePattern = /^[A-Za-z0-9\-_.-]+$/;


export const inventoryNamePattern = /^[A-Za-z0-9\-_/]+$/;
export const presetNamePattern = /^[A-Za-z0-9\-_|.]+$/;
export const publisherNamePattern = /^[A-Za-z0-9\-_|.]+$/;
export const generalNamePattern = /[^"' ]+$/

export const predefinedOrigins = ["default", "preset", "site", "inventory", "preset-inventory"]

export const variableTypes = ["number", "string", "url", "object", "array", "boolean"]

export function showInitials(name: string) {
    let parts = name.split(' ')
    let initials = ''
    if (parts.length === 1) {
        return parts[0][0] + parts[0][1]
    }
    for (var i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
            initials += parts[i][0]
        }
    }
    return initials
}

export function isEnvironment(env: string) {
    return window.location.href.indexOf(env) > -1
}

export function extractNameFromEmail(email: string) {
    // Get the part of the email before the "@"
    const namePart = email.split('@')[0];

    return namePart;
}


export function convertDate(dateString: string) {
    let date = new Date(dateString);
    let formattedDate = date.toLocaleDateString(undefined, {
        timeZone: 'Europe/Zurich',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
    let formattedTime = date.toLocaleTimeString(undefined, {
        timeZone: 'Europe/Zurich',
        hour: '2-digit',
        minute: '2-digit'
    });
    return formattedDate + " at " + formattedTime + "";
}

export const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

export const removeObjectsWithEmptyFirstKeyAndNullValue = (arr: any) => {
    for (let i = 0; i < arr.length; i++) {
        const [firstKey, firstValue] = Object.entries(arr[i])[0];
        if ((Array.isArray(firstValue) && firstValue.length === 0) || firstValue === null) {
            arr.splice(i, 1);
            i--;
        }
    }
    return arr;
};


export function searchNestedObject(obj: any, targetKey: string): any | undefined {
    for (const key in obj) {
        if (key === targetKey) {
            return obj[key];
        }

        if (typeof obj[key] === 'object') {
            const result = searchNestedObject(obj[key], targetKey);
            if (result) {
                return result;
            }
        }
    }
    return undefined;
}

//find the key of every object that has childKey in it
export const searchModuleKeys = (obj: any, childKey: string) => {
    let keys: string[] = [];
    for (const key in obj) {
        //find the key of every object that has vars in it
        //CHeck if obj[key].hidden is true additionaly
        if (obj[key][childKey] && !obj[key].meta.hidden) {
            keys.push(key)
        } else {
            if (typeof obj[key] === 'object') {
                const result = searchModuleKeys(obj[key], childKey);
                if (result) {
                    keys.push(...result);
                }
            }
        }

    }
    return keys;
}


export const searchModuleKeysForInventory = (obj: any, childKey: string) => {
    let keys: string[] = [];
    for (const key in obj) {
        //find the key of every  object that has vars in it
        if (obj[key] && obj[key][childKey] && obj[key].meta?.configurableForInventory) {
            keys.push(key)
        } else {
            if (typeof obj[key] === 'object') {
                const result = searchModuleKeysForInventory(obj[key], childKey);
                if (result) {
                    keys.push(...result);
                }
            }
        }
    }
    return keys;
}
export const mergeDeep = (target: any, source: any) => {
    const output = {...target};
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target) || target[key] === null) {
                    Object.assign(output, {[key]: source[key]});
                } else {
                    output[key] = mergeDeep(target[key], source[key]);
                }
            } else {
                Object.assign(output, {[key]: source[key]});
            }
        });
    }
    return output;
};


const isObject = (item: any) => {
    return (item && typeof item === 'object' && !Array.isArray(item));
}


export const getInitials = (fullName: string): string => {
    const parts = fullName.split('.');
    if (parts.length === 2) {
        const firstInitial = parts[0].charAt(0).toUpperCase();
        const secondInitial = parts[1].charAt(0).toUpperCase();
        return firstInitial + secondInitial;
    }
    return '';
};

export const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
};




