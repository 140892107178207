import styled from 'styled-components';
import {Button as BootstrapButton} from 'react-bootstrap';
import {interFontStyle} from '../styles/Fonts';
import {device} from '../styles/Breakpoints';


export const Button = styled(BootstrapButton)`

  i {
    margin-right: 5px;
  }

  @media ${device.sm} {
    span {
      display: none;
    }
  }

  ${interFontStyle}
  font-size: clamp(11px, 1vw, 14px);
  padding: 12px clamp(10px, 2vw, 24px);
  border-radius: 8px;
  box-shadow: 0px 0.5px 0.75px 0px #0B4A6FB2;
  border: none;

  ${props => {
    switch (props.variant) {
      case 'primary':
        return `
          background: #1570EF;
          &:hover {
            background: #6495ED;
          }
        `;
      case 'success':
        return `
          background: #65A30D;
          &:hover {
            background: #9ACD32;
          }
        `;
      case 'text-secondary':
        return `
          background: none !important;
          color: #3985F1;
          &:hover {
            background: #9ACD32;
          }
        `;

      case 'outline-secondary':
        return `
          box-shadow: 0px 1px 2px 0px #1018280D;
          color: #3985F1;
          border: 1px solid #D0D5DD;
          background: #ffffff;
          &:hover {
            background: #E0F2FE;
            border-color: #D0D5DD;
            color: #3985F1;
          }
        `;
      case 'remove':
        return `
            background: none;
            box-shadow: none;
            color: red;
            padding: 0;
        `;
      case 'link':
        return `
            background: none;
            box-shadow: none;
            border: none
            padding: 0;
        `;
            // Add more cases for other variants
      default:
        return '';
    }
  }}
`;
